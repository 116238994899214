import { NotificationService } from 'src/app/shared/notification.service';
import { Component, ViewChild } from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';
import { AuthenticationService } from '../authentication.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
})
export class ShellComponent {
    @ViewChild('matDrawerContent')
    private matDrawerContent: MatDrawerContent;

    public get isAdmin$(): Observable<boolean> {
        return this.authService.isAdmin$;
    }

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private notification: NotificationService
    ) {}

    public onActivate() {
        this.matDrawerContent?.getElementRef()?.nativeElement?.scrollTo({ top: 0 });
    }

    public logout() {
        this.authService.logout().subscribe({
            next: result => {
                if (result) {
                    this.router.navigateByUrl('/login');
                }
            },
            error: error => {
                this.notification.error('Die Abmeldung war nicht erfolgreich', error);
            },
        });
    }
}
