import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private snackBar: MatSnackBar) {}

    public success(message: string, options: MatSnackBarConfig<void> = {}): void {
        this.snackBar.open(message, undefined, {
            panelClass: 'snackbar-success',
            ...options,
            duration: options.duration || 5000,
        });
    }

    public error(message: string, _e: Error, options: MatSnackBarConfig<void> = {}): void {
        this.snackBar.open(message, undefined, {
            panelClass: 'snackbar-error',
            ...options,
            duration: options.duration || 5000,
        });
    }
}
