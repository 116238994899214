import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BackendSharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { ShellComponent } from './shell/shell.component';
import { NotFoundComponent } from './not-found/not-found.component';
import '@angular/common/locales/global/de';
import { HttpLink } from 'apollo-angular/http';

@NgModule({
    declarations: [AppComponent, ShellComponent, NotFoundComponent],
    imports: [BrowserModule, BackendSharedModule, AppRoutingModule, BrowserAnimationsModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: AuthenticationService,
            multi: true,
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => ({
                cache: new InMemoryCache(),
                link: httpLink.create({
                    uri: [environment.apiUrl, 'api/graphql'].join('/'),
                }),
            }),
            deps: [HttpLink],
        },
        { provide: LOCALE_ID, useValue: 'de' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
