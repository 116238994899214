import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';

import { SelectSideBySideComponent } from './select-side-by-side/select-side-by-side.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UserPreviewComponent } from './user-preview/user-preview.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { MarkdownPipe } from './markdown.pipe';
import { SuccessComponent } from './message.service';

import { MarkdownEditorDirective } from './markdown-editor.directive';
import { MaxLengthIndicatorComponent } from './max-length-indicator/max-length-indicator.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { OverallFootnotes } from './footnote.pipe';
import { FootnotesHref } from './footnote-href.pipe';
import { IdFromIri } from './pipes/id-from-iri.pipe';
import { MatStepperScrollDirective } from './mat-stepper-scroll.directive';
import { ColorPreviewComponent } from './color-preview/color-preview.component';
import { PropertiesListComponent } from './properties-list/properties-list.component';
import { FootnoteSupComponent } from './footnote-sup/footnote-sup.component';

@NgModule({
    declarations: [
        MarkdownPipe,
        MaxLengthIndicatorComponent,
        OverallFootnotes,
        FootnotesHref,
        IdFromIri,
        FootnoteSupComponent,
    ],
    imports: [CommonModule],
    exports: [
        CommonModule,
        MarkdownPipe,
        MaxLengthIndicatorComponent,
        OverallFootnotes,
        FootnotesHref,
        FootnoteSupComponent,
        IdFromIri,
    ],
})
export class FrontendBackendSharedModule {}

@NgModule({
    declarations: [
        SelectSideBySideComponent,
        UserPreviewComponent,
        ColorPreviewComponent,
        LoadingIndicatorComponent,
        SuccessComponent,
        MarkdownEditorDirective,
        SearchFieldComponent,
        MatStepperScrollDirective,
        PropertiesListComponent,
    ],
    imports: [
        FrontendBackendSharedModule,
        MatCardModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatSidenavModule,
        MatListModule,
        MatProgressBarModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        ScrollingModule,
        MatChipsModule,
        MatTooltipModule,
        MatToolbarModule,
        MatTableModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTreeModule,
        MatCheckboxModule,
        MatRadioModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatDialogModule,
    ],
    exports: [
        MatCardModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatSidenavModule,
        MatListModule,
        MatProgressBarModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        ScrollingModule,
        MatChipsModule,
        MatTooltipModule,
        MatToolbarModule,
        MatTableModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTreeModule,
        MatCheckboxModule,
        MatRadioModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatDialogModule,
        SelectSideBySideComponent,
        UserPreviewComponent,
        ColorPreviewComponent,
        LoadingIndicatorComponent,
        MarkdownPipe,
        MarkdownEditorDirective,
        MaxLengthIndicatorComponent,
        SearchFieldComponent,
        OverallFootnotes,
        FootnotesHref,
        IdFromIri,
        MatStepperScrollDirective,
        PropertiesListComponent,
    ],
})
export class BackendSharedModule {}
